<template>
  <v-img :src="headerLogoUrl" :width="logoWidth" :height="logoHeight" :max-width="logoWidth" :max-height="logoHeight" :contain="true"/>
</template>

<script>
  import {mapGetters, mapState} from "vuex"

  export default {
    props: ['src'],

    name: "HeaderLogo",

    computed: {
      ...mapGetters({
        'imageUrl': 'main/imageBaseUrl'
      }),
      ...mapState({
        config: state => state.config.server.portalUIConfiguration.portalHeaderConfiguration
      }),

      logoHeight() {
        if (this.$vuetify.breakpoint.smAndUp) {
          return this.config.headerLogoHeight
        }
        return this.config.mobileHeaderLogoHeight
      },

      logoWidth() {
        if (this.$vuetify.breakpoint.smAndUp) {
          return this.config.headerLogoWidth
        }
        return this.config.mobileHeaderLogoWidth
      },

      headerLogoUrl() {
        if (this.$vuetify.breakpoint.smAndUp) {
          return this.imageUrl + this.config.logoName
        }
        return this.imageUrl + this.config.mobileLogoName
      }
    }
  }
</script>

<style scoped>
  img {
    vertical-align: middle;
    text-align: left;
    margin: auto;
  }

  .helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
</style>
