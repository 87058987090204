<template>
  <div>
    <v-list dense class="pb-0">
      <asset-container-item folder-icon="mdi-home" folder-icon-color="secondary" item-name="Home"
                            @itemClick="loadHome"/>
    </v-list>
    <v-subheader v-if="loading">
      <v-spacer></v-spacer>
      <v-progress-linear :indeterminate="true"></v-progress-linear>
      <v-spacer></v-spacer>
    </v-subheader>
    <v-list dense v-else class="pt-0">
      <v-divider></v-divider>
      <asset-container-item
          v-if="breadcrumbs.length > 0"
          :folder-icon-color="currentParent.color()"
          folder-icon="mdi-folder"
          :item-name="currentParent.name()"
          @actionClick="loadParent()"
          @itemClick="loadContainerAssets(currentParent)"
          action-icon="mdi-chevron-up"
          action-icon-color="secondary"
      />
      <v-divider v-if="breadcrumbs.length > 0"></v-divider>
      <asset-container-item
          v-for="container in containers" :key="container.id"
          item-class="pl-6"
          :folder-icon-color="container.color()"
          :item-name="container.name()"
          :show-action-icon="container.hasChildren()"
          folder-icon="mdi-folder"
          action-icon="mdi-chevron-right"
          @itemClick="loadContainerAssets(container)"
          @actionClick="loadChildren(container)"
      />
    </v-list>
  </div>
</template>
<script>
  import {mapActions, mapGetters, mapState} from 'vuex'
  import AssetContainerItem from "./AssetContainerItem"

  export default {
    components: {AssetContainerItem},
    methods: {
      ...mapActions({
        'loadContainers': 'container/loadContainers',
        'loadNextContainers': 'container/loadNextContainers',
        'loadParentContainer': 'container/loadParent',
        'loadAssets': 'assets/loadAssets'
      }),

      loadParent() {
        this.loadParentContainer()
      },

      loadChildren(container) {
        this.loadContainers(container)
      },

      loadContainerAssets(container) {
        this.$store.commit('container/SELECTED_CONTAINER', container)
        this.$store.commit('search/SET_CONTAINER_SEARCH_VALUE', container)
        this.$store.dispatch('search/updateQueryValues')
      },

      loadHome() {
        this.$store.commit('container/RESET_BREADCRUMBS')
        this.loadContainers({id: this.config.navigationRootIdentifier, root: true})
      }
    },
    created() {
      this.loadContainers({id: this.config.navigationRootIdentifier, root: true})
      //this controls which assets are initially loaded
      this.$store.commit("container/SELECTED_CONTAINER", {id: this.config.navigationRootIdentifier})
    },
    computed: {
      ...mapState({
        containers: state => state.container.containers,
        loading: state => state.container.loading,
        breadcrumbs: state => state.container.breadcrumbs,
        config: state => state.config.server
      }),
      ...mapGetters({
        'currentParent': 'container/currentContainer'
      })
    }

  }
</script>
<style lang="css" scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .slide-leave-active,
  .slide-enter-active {
    transition: 1000ms;
  }

  .slide-enter {
    transform: translate(100%, 0);
  }

  .slide-leave-to {
    transform: translate(-100%, 0);
  }
</style>
