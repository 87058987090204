<template>
	<v-toolbar :style="fixed" :height="config.searchInformationHeaderHeight">
		<v-container fluid style="padding: 0">
			<div>
				<div style="float: right; width: auto;  padding: 7px 0; vertical-align: center">
					<v-chip v-if="hasQueryValues" @click:close="clearAll" small color="primary" close>
						{{$t('searchInformationHeader.clearAll')}}
					</v-chip>
					<v-chip style="left: 10px;" class="mr-2" small color="primary">{{$t('searchInformationHeader.totalAssets')}}: {{totalAssets}}
					</v-chip>
				</div>
				<div>
					<active-filter-panel/>
				</div>
			</div>
		</v-container>
	</v-toolbar>
</template>

<script>
	import ActiveFilterPanel from "./ActiveFilterPanel"
	import {mapState} from "vuex"

	export default {
		name: "SearchInformationHeader",
		components: {ActiveFilterPanel},
		methods: {
			clearAll() {
				this.$store.commit("search/RESET_ALL_SEARCH_VALUES")
			}
		},
		computed: {
			...mapState({
				config: state => state.config.server.portalUIConfiguration,
				ui: state => state.config.local.ui,
				totalAssets: state => state.assets.totalAssets,
				queryValues: state => state.search.queryValues
			}),
			hasQueryValues() {
				if (this.queryValues.terms.length > 0) return true
				if (this.queryValues.containers.length > 0) return true
				if (this.queryValues.keywords.length > 0) return true
				return false
			},
			fixed() {

				let pr = this.config.navigationSideBarWidth + 'px'
				if (!this.ui.showLeftSideBar) {
					pr = 0
				}
				return {
					'position': 'fixed',
					'z-index': 2,
					'width': '100%',
					'padding-right': pr
				}
			}
		}
	}
</script>

<style scoped>

</style>
