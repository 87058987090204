<template>
          <v-chip-group class="pa-0 ma-0">
          <v-chip close @click:close="close(tag,'keyword')" small v-for="tag in values.keywords" :key="tag.id">{{tag.name}}
          </v-chip>
          <v-chip close @click:close="close(tag,'term')" small v-for="(tag,i) in values.terms" :key="i">{{tag}}
          </v-chip>
          <v-chip close @click:close="close(tag,'container')" small v-for="tag in values.container" :key="tag.id">{{tag}}
          </v-chip>
          </v-chip-group>
</template>

<script>
  import {mapState} from "vuex"

  export default {
    name: "ActiveFilterPanel",
    methods: {
      close(tag, type) {
        this.$store.commit("search/REMOVE_SEARCH_VALUE", {value: tag, type: type})
      }
    },
    computed: {
      ...mapState({
        values: state => state.search.queryValues
      }),
      tags() {
        let tags = []
        tags.concat(this.values.terms.map(term => {
          return {name: term, type: "term"}
        }))

        tags.concat(this.values.keywords.map(keyword => {
          return {name: keyword.name, type: "keyword"}
        }))

        tags.concat(this.values.containers.map(container => {
          return {name: container, type: "container"}
        }))
        return tags
      }
    }
  }
</script>

<style scoped>
  .v-slide-group__next, .v-slide-group__prev {
    width: 38px !important;
  }
</style>
